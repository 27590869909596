const reviews = [
  'วันนี้เข้าไปคุณครูน่ารักมากเลย ดูแลน้องจูโน่ดีมาก',
  'ครูสวยและสอนสนุกมาก',
  'ถูกมากๆ เลยค่ะ แถมสอนดีด้วย วันนี้เอาน้องไปทดลองอยู่ดู รู้สึกน้องมีความตั้งใจมากกว่าตอนอยู่บ้านอีกค่ะ',
  'ทางโรงเรียนต้อนรับเราดีมากเลย รู้สึกอบอุ่นมาก',
  'ครูดีมาก ๆ เลย สนใจดี และพยายามทำความเข้าใจลูกเรามาก',
  `ทางเข้าไปโรงเรียนลำบากมาก เป้นถนนลูกรังแต่เข้าไปถึงครูต้อนรับดีมาก ดูแลเด็กดีมากใส่ใจมาก ดีมากเลยค่ะ`,
  `เด็กในโรงเรียนคนอื่นน้ำใจดีมากค่ะ เข้ากันได้ดีมาก`,
  `ลูก happy ดีมากเลยค่ะ`,
  `อาหารอร่อยมากค่ะ ครูก็นิสัยดีมาก เข้าใจเด็กดี`,
  `สถานที่เหมาะสมกับการเลี้ยงดูเด็กมาก รอบ ๆ มีสนามหญ้ามีต้นไม้ให้วิ่งเล่น`,
  `ตอนแรกไม่แน่ใจว่าลูกจะตามทันเพื่อนไหม และจะมีอะไรทำร้ายเขาไหม แต่ครูดูแลลูกเราดีมากค่ะ ตอนนี้ค่อนข้างดีเลยทีเดียว`,
  `เปิดโลกให้น้องมินมาก ๆ เลยค่ะ ตอนไปรับเขากลับมายังเล่าเรื่องที่เขาเจอให้ฟังตลอดเวลาเลย น่าจะชอบมาก ๆ เลยค่ะ น้องเป็น hyper ค่ะ แนะนำเลยค่ะ`,
  `ทางเข้าไปโรงเรียนอาจจะแคบไปนิดหนึ่งนะคะ ที่จอดรถเล็กมาก ๆ แต่ลูกดูสนุกกับสิ่งที่ครูสอนมากเลยค่ะ และดูทำได้ดีด้วย วันนี้ลูกไปวาดรูปมากค่ะกลับบ้านมาเอามาวาดต่อด้วย น่ารักมากเลยค่ะ`,
  `ลูกเป็น LD ค่ะ เรียนตามเพื่อนที่โรงเรียนเก่าไม่ทันเลย เลยย้ายเขามาเรียนที่นี้ดูแลดีมาก ๆ ค่ะ ตอนนี้เขาเริ่มเรียนรู้ได้เขียนได้ดีแล้ว`
]

const names = `กัลยรัตน์ ปารีณา สมพร สมหมาย สมหวัง กาญจนา จันทรา ปุณวิภา ภัทรวดี กาญจนา รัตนวดี จิราภร นทีนาถ บงกช ภาวินี`.split(' ')

const randName = () => names[Math.floor(Math.random() * names.length)]
const randRating = () => Math.floor(Math.random() * 2) + 4

const list = reviews.map((x, i) => ({ id: i + 1, rating: randRating(), username: randName(), detail: x, photo: `https://i.pravatar.cc/300?cb=${Math.floor(Math.random() * 1000)}` }))

export default list
