<template lang="pug">
  section.story
    h1 จากเด็ก LD ในวันนั้นสู่ประตูฝันแห่งดวงดาวในวันนี้

    p มีเรื่องราวมากมายที่เราเคยได้ยินมาจากปากต่อปาก จากรุ่นสู่รุ่น หรืออาจจะเคยพบเจอกับตนเองในเรื่องของเด็กที่มีความต้องการการดูแลพิเศษ ไม่ว่าจะเป็นกลุ่มของเด็กออทิสติก กลุ่มของเด็ก ADHD หรือกลุ่มของเด็กที่มีภาวะการบกพร่องทางการเรียนรู้

    p แล้วเรื่องราวที่คุณเคยได้พบเจอมาเป็นอย่างไรล่ะ

    h2 ความไม่พิเศษที่อยู่ในความพิเศษ

    p ย้อนเวลากลับไป เมื่อหลายปีก่อน ในช่วงวัยอายุประถมต้น ครอบครัวหลายครอบครัวคงอยากเพิ่มพัฒนาการที่ดีและการเรียนรู้ให้กับเด็กวัย 9 ปี แต่ก็มีบางครอบครัวที่อยากจะมอบสิ่งที่ดีที่สุดแก่ลูกแต่ลูกกลับมีความพิเศษมากกว่าเด็กคนอื่น ๆ ในวัยเดียวกัน

    p น้องจูน เด็กสาวน่ารักวัย 10 ปี พี่สาวของเธอเป็นคุณครูที่คอยดูแลเธอมาตลอดจนถึงปัจจุบัน น้องจูนเป็นคนที่มีความพิเศษอยู่ในตัวเองสูงมาก เธอมีพัฒนาการและการเรียนรู้แต่เธอแค่ต้องการความเข้าใจและเวลาในการปรับตัวที่มากกว่าเด็กปกติเล็กน้อย ในส่วนของครอบครัวของเธอนั้น มีฐานะปานกลางและยังไม่มีความเข้าใจที่มากพอในเรื่องความพิเศษของน้องจูน

    p เธอและพี่สาวของเธออาศัยอยู่ด้วยกันและช่วยเหลือดูแลกันมาโดยตลอด แต่ด้วยความจำเป็นทางด้านการเรียนและการทำงาน พี่สาวเธอตัดสินใจที่จะส่งเธอให้เรียนรู้กับครูเชาว์ เชาวลิต ลาดสมัย ให้เลี้ยงดูน้องจูนในบางช่วงเวลา

    figure
      img(src="../../img/article-01.jpg")
      figcaption ครูเชาว์กับเด็กที่มาเรียนรู้กับครูเชาว์ ที่มา - Posttoday

    p ตามประวัติแล้ว ครูเชาว์มีความพิเศษติดตัวมาตั้งแต่กำเนิดเช่นกันนั้นก็คือครูเชาว์จะเป็นคนที่ต้องการเวลาในการเรียนรู้อะไรบางอย่างมากกว่าปกติเพื่อเก็บรายละเอียดให้ได้มากที่สุดและคุณครูยังมีความพิเศษทางด้านร่างกายมากกว่าคนปกติอีกด้วย แต่ด้วความพิเศษนี้การเรียนรู้กับครูเชาว์ ที่เข้มแข็งกับชีวิตมาโดยตลอดจนเรียนจบปริญญาตรี คระศึกษาศาสตร์ สาขาพลศึกษา มหาวิทยาลัยเกษตรศาสตร์ได้นั้นถือเป็นเรื่องที่เพิ่มความพิเศษแก่เด็กอายุ 10 ปีแบบน้องจูนได้

    h2 มากกว่าครู

    p น้องจูนเรียนกับครูเชาว์มาโดยตลอด จนกระทั่งเธอเรียนปริญญาตรี ระหว่างทางที่เธอเรียนกับครูเชาวน์นั้น เธอก็ยังได้เรียนหนังสือในโรงเรียนปกติร่วมกับคนปกติไปด้วย แต่เธอใช้ชีวิตในการศึกษาเรียนรู้อยู่กับครูเชาว์จนเกือบจะเรียกได้ว่าเป็นครูที่ปลูกฝั่งความดีและพฤติกรรมที่ดีในสังคมในกับเด็กที่มีความต้องการพิเศษแบบน้องจูนได้

    p นอกจากนั้นครูเชาว์ยังช่วยเพิ่มความเชื่อมั่นในตัวเองของน้องจูนขึ้นมา และให้พลังบวกแก่น้องในตอนที่น้องถูกกระทบกระเทือนทางด้านจิตใจมาจากเพื่อนที่โรงเรียน ทำให้น้องรู้สึกว่าโลกนี้มันไม่ได้มีแค่ถ้อยคำที่แย่ไปทั้งหมด ยังมีอีกหลายคนที่คอยสนับสนุนและช่วยเหลือน้องที่เป็นเด็กพิเศษอยู่เสมอ

    figure
      img(src="../../img/article-02.jpg")
      figcaption เด็กที่ครูเชาว์รับสอนที่กำลังทำกิจกรรมร่วมกันอยู่ ที่มา - Posttoday

    p ไม่ใช่แค่ให้คำแนะนำและสั่งสอนไปในทางที่ดีเท่านั้น ครูเชาว์ยังมีสอนในส่วนของการใช้ชีวิตในชีวิตประจำวัน การปรับตัวต่าง ๆ ที่ถ่ายทอดให้กับน้องจูนมาเรื่อย ๆ จนกระทั่งน้องจูนเรียนอยู่ในระดับปริญญาตรี คณะนิเทศศาสตร์ มหาวิทยาลัยนเรศวร

    h2 ปลายทางเดินแห่งความฝัน

    p ในวันที่น้องจูนเรียนในระดับปริญญาตรี ปีที่ 4 คณะนิเทศศาสตร์ พร้อมกับมีความพิเศษอยู่ในตัวเอง เธอได้กลับมาที่บ้านเพื่อกลับมาหาครอบครัวและพ่อแม่พี่สาวของเธอ เธอเปิดเจอสมุดที่เขียนข้อความที่ไม่สามารถแปลความหมายได้ ด้านในมีรอยขีดเขียนไปมา พร้อมกับสีระบายทั่วหน้ากระดาษ

    p นั่นคือสมุดของน้องจูน เมื่อสิบกว่าปีที่แล้วที่น้องจูนต้องต่อสู้กับอาการ LD หรือ Learning Disorder ที่ไม่สามารถเข้าใจได้แม้กระทั่งคำพูด การอ่าน การเขียน และพีชคณิตเบื้องต้นได้ แต่ตอนนี้เธอกำลังจะเรียนจบปริญญาตรีในคณะนิเทศศาสตร์ จากมหาวิทยาลัยนเรศวร ในปีค.ศ. 2020 นี้

    p จากความต่อสู้และพยายามอยู่กับความพิเศษทางสติปัญญาของตนเอง มีครูเชาว์คอยสอน คอยช่วงเหลือในหลาย ๆ ด้าน ทั้งด้านการเรียนและด้านจิตใจ มีครอบครัวที่ปรับตัวให้เข้ากับความพิเศษของน้องจูนที่ละนิด ทำให้น้องจูนได้เติบโตมาในสังคมที่ดีได้ และกำลังจะมีชีวิตที่ดีขึ้นไปเรื่อย ๆ จากความก้าวหน้าทางด้านการเรียนรู้และการปรับตัวเข้ากับสังคมโลก

    p น้องจูนไม่ได้เป็นเพียงแค่คนเดียวที่จะมีชีวิตที่ขึ้นดีพร้อมกับความพิเศษในตัวเองแบบนี้ ยังมีอีกหลายคนที่รอการเปล่งประกายแบบน้องจูน และเชื่อว่าครูเชาว์จะสามารถขัดเกลาเด็กในเติบโตมาในสังคม และเป็นเยาวชนที่ดีของประเทศไทยในอนาคตได้ไม่มากก็น้อย

    p ขอบคุณข้อมูลจากการสัมภาษณ์: น้องจูนและครอบครัว, คุณครูเชาว์ เชาวลิต ลาดสมัย
</template>

<script>
export default {}
</script>

<style lang="sass" scoped>
  @use '../../styles/variables' as *

  h1
    font-family: $heading-font
    font-size: 28px
    line-height: 40px

  h2
    font-family: $heading-font
    font-size: 20px
    font-weight: bold
    line-height: 30px

  p
    font-family: $body-font
    line-height: 25px

  img
    width: 100%
    max-width: 800px

  figcaption
    margin-top: 10px
    font-family: $body-font

  figure
    width: 100%
    margin: 50px auto
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
</style>
