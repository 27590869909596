<script>
export default [
  {
    title: 'CBD Oil น้ำมันกัญชายาวิเศษ<br />สำหรับเด็กพิเศษ',
  },
  {
    title: 'สิ่งล้ำค่าที่พ่อแม่ยุคใหม่ควรมอบให้<br />แก่ลูกคนพิเศษ',
  },
  {
    title: 'วิธีรับมือถ้าลูกมีแฟนเป็น<br />เด็กออทิสติก',
  },
]
</script>
